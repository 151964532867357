var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":_vm.formData.type.value === 'event' ? '4' : '6'}},[_c('b-form-group',{attrs:{"label":"Cím"}},[_c('validation-provider',{attrs:{"name":"cím","rules":"required","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"cím"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.formData.type.value !== 'event')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Dátum"}},[_c('validation-provider',{attrs:{"name":"dátum","rules":"","vid":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3331321834)})],1)],1):_vm._e(),(_vm.formData.type.value === 'event')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Kezdő dátum"}},[_c('validation-provider',{attrs:{"name":"kezdő dátum","rules":"","vid":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1604420693)})],1)],1):_vm._e(),(_vm.formData.type.value === 'event')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Befejező dátum"}},[_c('validation-provider',{attrs:{"name":"befejező dátum","rules":"","vid":"end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2652001722)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Típus"}},[_c('validation-provider',{attrs:{"name":"típus","rules":"required","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.typeOptions},on:{"input":_vm.typeChanged},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Státusz"}},[_c('validation-provider',{attrs:{"name":"státusz","rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.statusOptions},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Címke"}},[_c('validation-provider',{attrs:{"name":"címke","vid":"tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","taggable":"","push-tags":"","options":_vm.tagOptions},model:{value:(_vm.formData.tags),callback:function ($$v) {_vm.$set(_vm.formData, "tags", $$v)},expression:"formData.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Szöveg"}},[_c('validation-provider',{attrs:{"name":"szöveg","rules":"required","vid":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }